import React, { useEffect, useState } from 'react';
import basketSVG from '../../assets/images/icons/quote.svg';

const BasketIcon = () => {
  const { PubSub } = window;
  const localBasket = JSON.parse(window.localStorage.getItem('ics-basket'));

  const [basketCount, setBasketCount] = useState(
    localBasket ? localBasket.length : 0,
  );

  useEffect(() => {
    PubSub.subscribe('BASKET-UPDATE', (msg, basket) => {
      setBasketCount(basket.length);
    });
  }, []);

  return (
    <>
      <img src={basketSVG} alt="Basket" className="nav-icon" />
      {basketCount > 0 && <p className="basket-count fl jc-c">{basketCount}</p>}
    </>
  );
};

export default BasketIcon;
